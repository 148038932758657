import React from "react";
import "tailwindcss/tailwind.css";

const index = ({ questions, currentQuestion, handleAnswerOptionClick, selectedAnswers }) => {
  return (
    <div className="answer-section flex flex-col space-y-4 mt-6">
      {questions[currentQuestion]?.answers &&
        Object.entries(questions[currentQuestion]?.answers).map(([key, value]) => {
          const isSelected = selectedAnswers[currentQuestion] && selectedAnswers[currentQuestion].answer === value;
          const baseClasses = "answer-options w-full text-left py-3 px-4 border border-gray-300 rounded shadow transition-colors duration-200";
          const conditionalClasses = isSelected ? "bg-blue-500 text-white" : "bg-white text-gray-800 hover:bg-blue-500 hover:text-white";
          return (
            value && (
              <button key={key} className={`${baseClasses} ${conditionalClasses}`} onClick={() => handleAnswerOptionClick(questions[currentQuestion]?.correct_answers[`${key}_correct`] === "true", value)}>
                {value}
              </button>
            )
          );
        })}
    </div>
  );
};

export default index;
