import React, { useState } from "react";
import axios from "axios";
import { FiSend } from "react-icons/fi";
import Title from "../home/Title";
import GoogleMapReact from "google-map-react";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [messages, setMessages] = useState("");

  // ================= Error Messages Start here =================
  const [errClientName, setErrClientName] = useState(false);
  const [errEmail, setErrEmail] = useState(false);
  const [errMessages, setErrMessage] = useState(false);
  // ================= Error Messages End here ===================
  const [seuccessMsg, setSuccessMsg] = useState("");
  // ================= Email Validation Start here ===============
  const EmailValidation = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
  };
  // ================= Email Validation End here =================

  const handleName = (e) => {
    setClientName(e.target.value);
    setErrClientName(false);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail(false);
  };
  const handleMessages = (e) => {
    setMessages(e.target.value);
    setErrMessage(false);
  };

  const RECAPTCHA_SITE_KEY = "6LdSI3AqAAAAAJqJM6o4-Rk8G9ZD6JMNXUznQIJg";

  const [isVerified, setIsVerified] = useState(false);

  const handleRecaptchaVerify = () => {
    setIsVerified(true);
  };

  const handleSend = (e) => {
    e.preventDefault();
    if (!clientName) {
      setErrClientName(true);
    }
    if (!email) {
      setErrEmail(true);
    } else {
      if (!EmailValidation(email)) {
        setErrEmail(true);
      }
    }
    if (!messages) {
      setErrMessage(true);
    }
    if (clientName && email && EmailValidation(email) && messages && isVerified) {
      axios.post("https://getform.io/f/f792aa54-6f6b-4793-be91-365b499fa1ff", {
        name: clientName,
        email: email,
        message: messages,
      });
      setSuccessMsg(`Heyhoo ${clientName}, Your message has been sent, please wait for my reply. Thank you ^_^`);
      setClientName("");
      setEmail("");
      setMessages("");
    }
  };
  return (
    <div className="w-full">
      <Title title="My" subTitle="Information" />
      <div className="w-full flex flex-col rounded-xl md:flex-row justify-between gap-4 md:gap-10 lgl:gap-20">
        <div className="h-80 w-full p-6 rounded-xl bg-black-800">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyD3RzE2fq7JvhFmDTbXyjj22jqIAytT7XU",
              language: "id",
            }}
            defaultCenter={{ lat: -6.21462, lng: 106.84513 }}
            defaultZoom={12}
            options={{ mapTypeId: "roadmap" }}
          />
        </div>
      </div>
      <div className="p-6 w-full flex flex-col md:flex-row justify-between gap-4 md:gap-10 lgl:gap-20">
        <div className="w-full lgl:w-1/2">
          <p className="flex gap-6 justify-between w-full text-lg text-[#ccc] py-4 border-b-[1px] border-b-zinc-600">
            <span className="bg-designColor text-gray-900 text-sm font-titleFont font-medium px-2 rounded-md flex items-center justify-center">Address:</span>
            Jakarta, Indonesia
          </p>
          <p className="flex justify-between w-full text-lg text-[#ccc] py-4 border-b-[1px] border-b-zinc-600">
            <span className="bg-designColor text-gray-900 text-sm font-titleFont font-medium px-2 rounded-md flex items-center justify-center">Phone:</span>
            +62852 93000102
          </p>
        </div>
        <div className="w-full lgl:w-1/2">
          <p className="flex justify-between lgl:gap-6 w-full text-lg text-[#ccc] py-4 border-b-[1px] border-b-zinc-600">
            <span className="bg-designColor text-gray-900 text-sm font-titleFont font-medium px-2 rounded-md flex items-center justify-center">Email:</span>
            abdulfikihk@gmail.com
          </p>
          <p className="flex justify-between w-full text-lg text-[#ccc] py-4 border-b-[1px] border-b-zinc-600">
            <span className="bg-designColor text-gray-900 text-sm font-titleFont font-medium px-2 rounded-md flex items-center justify-center">Freelance:</span>
            Available
          </p>
        </div>
      </div>
      <div className="w-full mt-10">
        <Title title="Send" subTitle="Messages" />
        {seuccessMsg ? (
          <p className="text-center text-base font-titleFont p-20 text-designColor">{seuccessMsg}</p>
        ) : (
          <form id="form" action="https://getform.io/f/f792aa54-6f6b-4793-be91-365b499fa1ff" method="POST" className="p-6 flex flex-col gap-6">
            <div className="w-full flex flex-col lgl:flex-row gap-4 lgl:gap-10 justify-between">
              <input
                onChange={handleName}
                value={clientName}
                className={`${errClientName ? "border-red-600 focus-visible:border-red-600" : "border-zinc-600 focus-visible:border-designColor"} w-full bg-transparent border-2 px-4 py-2 text-base text-gray-200 outline-none duration-300`}
                // className="w-full bg-transparent border-2 px-4 py-2 text-base text-gray-200 border-zinc-600 focus-visible:border-designColor outline-none duration-300"
                type="text"
                placeholder="Full Name"
              />
              <input
                onChange={handleEmail}
                value={email}
                className={`${errEmail ? "border-red-600 focus-visible:border-red-600" : "border-zinc-600 focus-visible:border-designColor"} w-full bg-transparent border-2 px-4 py-2 text-base text-gray-200 outline-none duration-300`}
                type="email"
                placeholder="Email Address"
              />
            </div>
            <textarea
              onChange={handleMessages}
              value={messages}
              className={`${
                errMessages ? "border-red-600 focus-visible:border-red-600" : "border-zinc-600 focus-visible:border-designColor"
              } w-full bg-transparent border-2 px-4 py-2 text-base text-gray-200 outline-none duration-300 resize-none`}
              placeholder="Your Message"
              rows="4"
            ></textarea>
            <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} onChange={handleRecaptchaVerify} />
            <button onClick={handleSend} className="text-base w-44 flex items-center gap-1 text-gray-200 hover:text-designColor duration-200">
              Send Message{" "}
              <span className="mt-1 text-designColor">
                <FiSend />
              </span>
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Contact;
