import React, { useState } from "react";
import Particle from "./components/roundDesigns/Particle";
import Home from "./Home";
import { FaRobot, FaUser } from "react-icons/fa"; // Icons for avatars
import axios from "axios"; // Import axios for API requests

function App() {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [userMessage, setUserMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]); // Stores chat history
  const [chatSize, setChatSize] = useState({ width: 320, height: 400 }); // Initial size

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const sendMessage = async () => {
    if (!userMessage) return;

    // Display the user's message
    setChatMessages([...chatMessages, { sender: "user", text: userMessage }]);

    // Call OpenAI's API
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo", // Use the desired OpenAI model
          messages: [{ role: "user", content: userMessage }],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer YOUR_OPENAI_API_KEY`,
          },
        }
      );

      // Extract and display AI's response
      const aiMessage = response.data.choices[0].message.content;
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: aiMessage },
      ]);
    } catch (error) {
      console.error("Error calling OpenAI API:", error);
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: "There was an error. Please try again later." },
      ]);
    }
    // Clear the input field
    setUserMessage("");
  };

  // Resize handler
  const handleMouseDown = (e) => {
    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = chatSize.width;
    const startHeight = chatSize.height;

    const doDrag = (e) => {
      setChatSize({
        width: Math.max(200, startWidth - (e.clientX - startX)), // Minimum width
        height: Math.max(200, startHeight - (e.clientY - startY)), // Minimum height
      });
    };

    const stopDrag = () => {
      document.removeEventListener("mousemove", doDrag);
      document.removeEventListener("mouseup", stopDrag);
    };

    document.addEventListener("mousemove", doDrag);
    document.addEventListener("mouseup", stopDrag);
  };

  return (
    <div className="w-full lgl:h-screen font-bodyfont overflow-hidden text-textColor bg-black relative">
      <div className="max-w-screen-2xl h-full mx-auto flex justify-center items-center">
        <Home />
      </div>

      {/* Chat Button */}
      <button
        className="fixed bottom-4 right-4 inline-flex items-center justify-center text-sm font-medium border rounded-full w-16 h-16 bg-black hover:bg-gray-700 cursor-pointer z-50"
        type="button"
        onClick={toggleChat}
        aria-haspopup="dialog"
        aria-expanded={isChatOpen}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="40"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="text-white"
        >
          <path d="M3 21L4.9 15.3A8.5 8.5 0 1 1 9.7 19z"></path>
        </svg>
      </button>

      {/* Chat Window */}
      {isChatOpen && (
        <div
          className="fixed bottom-20 right-4 bg-white rounded-lg shadow-lg p-4 z-50 flex flex-col"
          style={{ width: chatSize.width, height: chatSize.height }}
        >
          <div className="flex justify-between items-center border-b pb-2">
            <h3 className="text-lg font-semibold text-black">Chat AI</h3>
            <button
              onClick={toggleChat}
              className="text-gray-500 hover:text-gray-800"
            >
              ✕
            </button>
          </div>
          <div className="mt-4 h-full overflow-y-auto space-y-4">
            {chatMessages.map((message, index) => (
              <div
                key={index}
                className={`flex items-start ${
                  message.sender === "user" ? "justify-end" : "justify-start"
                }`}
              >
                {message.sender === "bot" && (
                  <FaRobot className="text-2xl text-gray-500 mr-2" />
                )}
                <div
                  className={`p-2 rounded-lg ${
                    message.sender === "user"
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-black"
                  }`}
                >
                  {message.text}
                </div>
                {message.sender === "user" && (
                  <FaUser className="text-2xl text-blue-500 ml-2" />
                )}
              </div>
            ))}
          </div>
          <div className="mt-4 flex items-center">
            <input
              type="text"
              className="text-black w-full px-4 py-2 border rounded-lg focus:outline-none"
              placeholder="Type a message..."
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
            />
            <button
              onClick={sendMessage}
              className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-lg"
            >
              Send
            </button>
          </div>
          {/* Resize handle */}
          <div
            onMouseDown={handleMouseDown}
            className="absolute left-0 top-0 w-4 h-4 cursor-se-resize bg-gray-300 rounded-br-lg"
          />
        </div>
      )}
      <div className="w-full h-full absolute top-0 left-0 z-10">
        <Particle />
      </div>
    </div>
  );
}

export default App;
