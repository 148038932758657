import React from "react";
import { FaFlag } from "react-icons/fa";
import { BiCodeAlt } from "react-icons/bi";
import { IoIosPaper } from "react-icons/io";
import { GiCheckMark } from "react-icons/gi";
import ResumeTitle from "./ResumeTitle";

const Skills = () => {
  return (
    <div className="w-full grid grid-cols-9 gap-10 lgl:gap-0 px-6">
      {/* =============== Design and Languages Start here =================== */}
      <div className="col-span-9 md:col-span-4">
        {/* =============== Design and Languages End here ===================== */}
        {/* =============== Coading and Knowledge Start here ==================== */}
        {/* =============== Coading and Knowledge End here ==================== */}

        <div className="col-span-9 md:col-span-4">
          <ResumeTitle title="Coding" icon={<BiCodeAlt />} />
          {/* web Design */}
          <div className="py-4">
            <div className="py-3 border-b-[1px] border-zinc-600">
              <p className="text-base text-textColor -mb-1.5">C, C++, C#, Java, PHP</p>
              <span className="w-full bg-zinc-600 h-1 inline-flex relative">
                <span className="w-[95%] h-full absolute top-0 left-0 bg-designColor"></span>
              </span>
            </div>
            {/* web Development */}
            <div className="py-3 border-b-[1px] border-zinc-600">
              <p className="text-base text-textColor -mb-1.5">Python, Go, Qml</p>
              <span className="w-full bg-zinc-600 h-1 inline-flex relative">
                <span className="w-[90%] h-full absolute top-0 left-0 bg-designColor"></span>
              </span>
            </div>
            {/* Mobile Application */}
            <div className="py-3 border-b-[1px] border-zinc-600">
              <p className="text-base text-textColor -mb-1.5">Javascript, TypeScript, Laravel</p>
              <span className="w-full bg-zinc-600 h-1 inline-flex relative">
                <span className="w-[85%] h-full absolute top-0 left-0 bg-designColor"></span>
              </span>
            </div>
            {/* UI Design */}
            <div className="py-3 border-b-[1px] border-zinc-600">
              <p className="text-base text-textColor -mb-1.5">ReactJS, ReactNative, VueJS, Angular, NodeJS</p>
              <span className="w-full bg-zinc-600 h-1 inline-flex relative">
                <span className="w-[80%] h-full absolute top-0 left-0 bg-designColor"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-full hidden lgl:flex justify-center items-center">
        <span className="w-[1px] h-full bg-zinc-600 inline-flex"></span>
      </div>
      <div className="col-span-9 md:col-span-4">
        <ResumeTitle title="Languages" icon={<FaFlag />} />
        {/* Indonesian */}
        <div className="py-4">
          <div className="py-3 border-b-[1px] border-zinc-600">
            <p className="text-base text-textColor -mb-1.5">Bahasa Indonesia</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[100%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          {/* English*/}
          <div className="py-3 border-b-[1px] border-zinc-600">
            <p className="text-base text-textColor -mb-1.5">English</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[70%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
        </div>
      </div>

      <div className="col-span-9 md:col-span-4">
        <ResumeTitle title="Knowledge" icon={<IoIosPaper />} />
        {/* English */}
        <ul className="py-4 flex flex-col gap-2 border-b-[1px] border-b-zinc-600">
          <li className="flex items-center gap-4 text-textColor">
            <span className="text-designColor text-lg">
              <GiCheckMark />
            </span>
            SQL, NoSQL, Big Data
          </li>
          <li className="flex items-center gap-4 text-textColor">
            <span className="text-designColor text-lg">
              <GiCheckMark />
            </span>
            Machine Learning, OpenCV, Computer Vision
          </li>
          <li className="flex items-center gap-4 text-textColor">
            <span className="text-designColor text-lg">
              <GiCheckMark />
            </span>
            Corel Draw, Adobe Ilustrator, Adobe Photoshop
          </li>
          <li className="flex items-center gap-4 text-textColor">
            <span className="text-designColor text-lg">
              <GiCheckMark />
            </span>
            IT Support, Networking, Hardware, Software
          </li>
          <li className="flex items-center gap-4 text-textColor">
            <span className="text-designColor text-lg">
              <GiCheckMark />
            </span>
            Communication, Teamwork, Creativity, Adaptability
          </li>
          <li className="flex items-center gap-4 text-textColor">
            <span className="text-designColor text-lg">
              <GiCheckMark />
            </span>
            Work Under Presure
          </li>
          <li className="flex items-center gap-4 text-textColor">
            <span className="text-designColor text-lg">
              <GiCheckMark />
            </span>
            Honesty, Conscientiousness, Discipline, Responsibility
          </li>
        </ul>
      </div>
      {/* =============== Design and Languages End here ===================== */}
    </div>
  );
};

export default Skills;
