import React from "react";
import { FaBuffer } from "react-icons/fa";
import { AiFillAndroid } from "react-icons/ai";
import { AiFillDatabase } from "react-icons/ai";
import { SiAntdesign } from "react-icons/si";
import { BiCodeAlt } from "react-icons/bi";
import { GiDeliveryDrone } from "react-icons/gi";
import { MdSecurity } from "react-icons/md";
import { MdOutlineComputer } from "react-icons/md";
import ServicesCard from "./ServicesCard";

const MyServices = () => {
  return (
<div className="grid grid-cols-1 md:grid-cols-2">
  <ServicesCard icons={<BiCodeAlt />} title="Web Development" subTitle="With expertise in languages like JavaScript, TypeScript, HTML, CSS, PHP, and Python, I create functional and responsive web applications. I've applied these skills to develop official websites, enhance UI/UX, and implement SEO for improved visibility." />
  
  <ServicesCard icons={<SiAntdesign />} title="Web Design" subTitle="I bring a robust design skillset using tools like Figma to craft visually appealing and user-centric websites. My experience includes designing interfaces that enhance user experience, reflecting my commitment to both functionality and aesthetic appeal." />
  
  <ServicesCard icons={<AiFillAndroid />} title="Android Development" subTitle="Specializing in Android development, I create efficient mobile applications with technologies like Kotlin and React Native, ensuring robust and high-performance solutions for user-friendly mobile experiences." />
  
  <ServicesCard icons={<FaBuffer />} title="Machine Learning & AI" subTitle="Experienced in machine learning, I use TensorFlow and PyTorch for tasks such as image recognition and NLP. In addition to implementing ML models, I've applied AI principles to develop intelligent systems, including chatbot applications and drone autonomy solutions." />
  
  <ServicesCard icons={<AiFillDatabase />} title="Data Analysis & Engineering" subTitle="As a data analyst, I leverage SQL and NoSQL databases to deliver insights that guide decision-making. My projects involve financial data analysis, implementing data-centric strategies, and maintaining efficient database systems for optimized performance." />
  
  <ServicesCard icons={<GiDeliveryDrone />} title="Drone Programming & IoT" subTitle="In my role with the robotics team, I develop algorithms for drone control systems, integrating machine learning for enhanced autonomy. This work includes object detection and navigation enhancements for various applications in autonomous drones." />

  <ServicesCard icons={<MdSecurity />} title="Cyber Analyst & Penetration Testing" subTitle="As a cyber analyst, I conduct comprehensive analyses to protect applications from security threats, using ISO 27001:2022 and NIST standards. I also perform penetration testing and vulnerability assessments to strengthen system resilience and cybersecurity strategies." />
  
  <ServicesCard icons={<MdOutlineComputer  />} title="IT Support & System Security" subTitle="With a focus on cybersecurity, I provide IT support that enhances system security. My experience includes implementing cybersecurity frameworks and conducting system audits, ensuring adherence to industry standards and safeguarding data integrity." />
</div>

  );
};

export default MyServices;
