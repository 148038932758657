import React from "react";
import ResumeTitle from "./ResumeTitle";
import { MdWork } from "react-icons/md";
import { GiGraduateCap } from "react-icons/gi";
import ResumeCard from "./ResumeCard";

const Education = () => {
  return (
    <div className="w-full grid grid-cols-9 px-6">
      <div className="col-span-9 md:col-span-4">
        <ResumeTitle title="Experience" icon={<MdWork />} />
        <ResumeCard badge="" fill="Sep 2024 - Present" title="Cyber Analyst - Central Bank of Indonesia" subTitle="Jakarta, Indonesia" des="Conduct comprehensive cyber analysis of disruptions in Bank Indonesia’s applications, ensuring adherence to high security standards within the Risk Management Department. Implement ISO 27005:2022 and NIST CSF, NIST SP 800-30, NIST SP 800-53 principles to enhance effective risk management and strengthen system resilience. Develop an AI chat application utilizing the Llama model, Streamlit, and LangChain, while performing automated Excel cyber analysis that generates downloadable results for quick insights." />
        <ResumeCard badge="Jun 2024 - Aug 2024"  title="xApp Developer ORAN 5G - NTUST" subTitle="Taipei City, Taiwan" des="Focused on developing innovative xApp applications for ORAN 5G to enhance energy efficiency, utilizing C, C++, and Python for new functions and APIs. Experienced in deploying and managing xApp applications using Kubernetes and Docker, ensuring scalable and efficient operations within 5G environments. Skilled in leveraging Machine Learning frameworks for advanced data collection and processing to optimize RAN control and improve overall system performance" />
        <ResumeCard badge="Jan 2024 - Feb 2024" title="IT Data Analyst - Otoritas Jasa Keuangan" subTitle="Jakarta, Indonesia" des="Engaged as an IT Data Analyst at Otoritas Jasa Keuangan, focusing on financial data analysis and insights. Proficient in utilizing C# programming language and SQL queries to extract, manipulate, and analyze financial datasets. Implementing data-driven strategies to provide valuable insights and support decision-making processes." />
        <ResumeCard badge="Jan 2024 - Jun 2024" title="Web Developer - University of Indonesia" subTitle="Depok, Indonesia" des="Contributing expertise as a Web Developer specializing in WordPress and SEO at the University of Indonesia. Responsible for designing and implementing dynamic and user-friendly websites using WordPress, optimizing site architecture, and implementing SEO strategies." />
        <ResumeCard badge="Dec 2021 - Jun 2024" title="VTOL Programming Division Technical Director" subTitle="Jakarta, Indonesia" des="Supervising the website ground control system and machine learning involves overseeing the central interface for drone operations and optimizing algorithms for autonomous capabilities and ensuring reliability in drone functions. " />
        <ResumeCard badge="Jul 2023 - Dec 2023" title="Web Programmer PT. Global Medika Digitama" subTitle="Surabaya, Indonesia" des="I am responsible for the development of a website for this company, utilizing Next.js as the framework for the frontend. Additionally, I am involved in the creation of a Clinic App, where the  backend is structured using Laravel. " />
        <ResumeCard badge="May 2023 - Oct 2023" title="Person In Charge of Web Developer Division CEO FTUI" subTitle="Jakarta, Indonesia" des="Tasked with building a website for event publication using TypeScript to ensure successful development. " />
        <ResumeCard badge="Jul 2023 - Aug 2023" title="System Developer PT. Garuda Maintenance Facility" subTitle="Jakarta, Indonesia" des="To enhance the effectiveness of reporting aircraft components to customers, implement a web‑based system using Angular and Nest JS for efcient data processing and reporting. " />
        <ResumeCard badge="May 2023 - Jun 2023" title="IT Support PT. Jakarta International Expo" subTitle="Jakarta, Indonesia" des="Supporting the IT Division for Jakarta Fair 2023 involves managing network infrastructure, confguring CCTV systems, handling ticketing machines, and ensuring overall operational readiness for the event. " />
        <ResumeCard badge="Jan 2022 - Jan 2023" title="Staff of Student Wallfare (IME FTUI 2022)" subTitle="Jakarta, Indonesia" des="Being part of the division that takes care of student welfare in the form of scholarships and tuition payments." />
        <ResumeCard badge="Jun 2022 - Jun 2022" title="Staff of Acomodation (IMCD 2022)" subTitle="Jakarta, Indonesia" des="As the one who takes care of the accommodation of the visitors. " />
        <ResumeCard badge="Oct 2021 - Oct 2023" title="Member of GDSC UI" subTitle="Jakarta, Indonesia" des="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum, itaque ipsa quae!" />
      </div>
      <div className="w-full h-full hidden lgl:flex justify-center items-center">
        <span className="w-[1px] h-full bg-zinc-600 inline-flex"></span>
      </div>
      <div className="col-span-9 md:col-span-4">
        <ResumeTitle title="Education" icon={<GiGraduateCap />} />
        <ResumeCard badge="" fill="2021 - Present" title="University of Indonesia" subTitle="Jakarta, Indonesia" des="Undergraduate in Computer Engineering, 3.86/4.00. Gold Medal At The National Informatics Olympiad (Lembaga Prestige Indonesia). Runner Up NXP HoverGames 3: Land, Sky, Food Supply " />
      </div>
    </div>
  );
};

export default Education;
