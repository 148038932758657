import React, { useState, useEffect } from "react";
import axios from "axios";
import Title from "../home/Title";
import AnswerSection from "./AnswerSection";
import { ThreeCircles } from "react-loader-spinner";

const QuizApp = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [score, setScore] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);

  const fetchQuestions = async () => {
    const response = await axios.get(`https://quizapi.io/api/v1/questions?apiKey=qLVVbu7dmh0PbdLoRkWm8Q4rlWpHzFGtTFuEOACj&limit=10&category=code`);
    setQuestions(response.data);
  };
  useEffect(() => {
    fetchQuestions();
  }, []);

  const handleAnswerOptionClick = (isCorrect, answer) => {
    const updatedSelectedAnswers = [...selectedAnswers];
    updatedSelectedAnswers[currentQuestion] = { answer, isCorrect };
    setSelectedAnswers(updatedSelectedAnswers);
  };

  const handleNextButtonClick = () => {
    const isCorrect = selectedAnswers[currentQuestion]?.isCorrect;
    if (isCorrect) {
      setScore((prev) => prev + 1);
    }
    setCurrentQuestion(currentQuestion + 1);
  };

  const handlePlayAgainClick = () => {
    setCurrentQuestion(0);
    setShowScore(false);
    setScore(0);
    setSelectedAnswers([]);
  };

  const handleFinishButtonClick = () => {
    const isCorrect = selectedAnswers[currentQuestion]?.isCorrect;
    if (isCorrect) {
      setScore((prev) => prev + 1);
    }
    setShowScore(true);
  };

  return (
    <div className=" w-full container mx-auto px-4 py-8">
      <Title title="Quiz" subTitle="Games" />
      <div className=" w-full game-wrapper flex flex-col items-center space-y-8">
        <div className="quiz w-full">
          {showScore ? (
            <div className="score-section flex flex-col items-center space-y-4">
              <h2 className="text-2xl font-semibold">Your Score: {score}</h2>
              <button className="playAgain-btn bg-blue-500 text-white py-2 px-4 rounded shadow" onClick={handlePlayAgainClick}>
                Play Again
              </button>
            </div>
          ) : (
            <>
              {questions.length > 0 ? (
                <>
                  <div className="question-section">
                    <div className="question-count text-lg font-semibold">
                      <span>{currentQuestion + 1}</span>/{questions.length}
                    </div>
                    <div className="question-text text-xl mt-2">{questions[currentQuestion]?.question}</div>
                  </div>
                  <AnswerSection questions={questions} currentQuestion={currentQuestion} handleAnswerOptionClick={handleAnswerOptionClick} selectedAnswers={selectedAnswers} />
                  <div className="navigation-buttons flex justify-between mt-4">
                    {currentQuestion > 0 && (
                      <button className="bg-blue-500 text-white py-2 px-4 rounded shadow" onClick={() => setCurrentQuestion(currentQuestion - 1)}>
                        Previous
                      </button>
                    )}
                    {currentQuestion < questions.length - 1 ? (
                      <button className="bg-blue-500 text-white py-2 px-4 rounded shadow" onClick={handleNextButtonClick}>
                        Next
                      </button>
                    ) : (
                      <button className="bg-green-500 text-white py-2 px-4 rounded shadow" onClick={handleFinishButtonClick}>
                        Finish
                      </button>
                    )}
                  </div>
                </>
              ) : (
                <div className="flex justify-center items-center mt-20">
                  <ThreeCircles height="100" width="100" color="#29a3ff" wrapperStyle={{}} wrapperClass="" visible={true} ariaLabel="three-circles-rotating" outerCircleColor="" innerCircleColor="" middleCircleColor="" />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuizApp;
